import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Text,
  NormalText,
  BoldText,
  ValueContainer,
  TextInfo,
  DownloadButton,
  ModalTitle,
  SubTitleModal,
  ModalTitleContainer,
  SubTitleModalContainer,
  ConfirmButton,
  ConfirmButtonText,
  ConfirmButtonContainer,
  CancelText,
  CancelTextContainer,
  CloseIconContainer,
  ModalContent,
  CloseIconDiv,
  WaitContainer,
  WaitText,
  GenerateContainer,
  DownloadContainer,
  DownloadText,
  AdditionalServiceTitle,
  AdditionalServiceText,
  AdditionalServiceSubTitle,
  AdditionalServiceOptions,
  AdditionalServiceOptionsContainer,
  AdditionalName,
  AdditionalValue,
  ValueInfoContainer,
  WaitContainerDesk,
  CautelarContainer,
  ValueContainerCautelar,
  TextCautelar,
} from './styles';
import Modal from 'react-modal';
import { enableTooltip } from '../../../../store/tooltip/actions';

import EcvIcon from '../../../../images/icons/generateEcvIcon.svg';
import CautelarIcon from '../../../../images/icons/generateCautelarIcon.svg';
import CloseIcon from '../../../../images/icons/closeIconModal.svg';
import WaitIcon from '../../../../images/icons/waitIconAdd.svg';
import DownloadIcon from '../../../../images/icons/downloadIcon.svg';
import { useSegment } from '../../../../hooks/useSegment';
import { generateDoc } from '../../../../services/generate-doc';
import { formatPrice } from '../../../../utils/formatPrice';

export const AdditionalServices = ({
  inspectionId,
  winningBid,
  year,
  make,
  model,
  pdfLink,
  releaseDownloadDoc,
  alreadyExist,
  serviceCreated,
}) => {
  const { trackAdditionalService } = useSegment();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 760);
  const [respondeCreatDoc, setRespondeCreatedDoc] = useState();

  const fetchData = async type => {
    try {
      const response = await generateDoc(inspectionId, type);
      setRespondeCreatedDoc(response);
      dispatch(enableTooltip({ messageId: 13 }));
    } catch (error) {
      console.error(error);
      dispatch(enableTooltip({ messageId: 14 }));
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    content: {
      width: isMobile ? 'auto' : '500px',
      height: 'fit-content',
      margin: 'auto',
      border: 'none',
      padding: '10px',
    },
  };

  const AdditionaServicesEnum = Object.freeze([
    {
      key: 'ECV',
      title: 'ECV',
      icon: EcvIcon,
      link: pdfLink.linkEcv,
      value: 80,
      showCard: serviceCreated?.ecv,
    },
    {
      key: 'CAUTELAR',
      title: 'Cautelar',
      icon: CautelarIcon,
      link: pdfLink.linkCautelar,
      value: 90,
      showCard: true,
    },
  ]);

  useEffect(() => {
    const handleResize = () => {
      if (isMobile && window.innerWidth <= 760) {
        return;
      }

      if (!isMobile && window.innerWidth > 760) {
        return;
      }

      if (isMobile && window.innerWidth > 760) {
        setIsMobile(false);
      }

      if (!isMobile && window.innerWidth <= 760) {
        setIsMobile(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  const handleSegment = (type, pdfLink) => {
    trackAdditionalService(
      new Date().getTime(),
      inspectionId,
      type,
      year,
      make,
      model,
      winningBid,
      pdfLink
    );
  };

  const CautelarMobile = () => {
    return respondeCreatDoc === undefined && !alreadyExist.exists ? (
      <CautelarContainer>
        <ValueContainerCautelar>
          <img className="icon" src={CautelarIcon} alt="ícone" />
          <TextCautelar>
            Perícia Cautelar <NormalText>por R$ 90,00</NormalText>
          </TextCautelar>
        </ValueContainerCautelar>
        <>
          <TextInfo>Você já pode adquirir a perícia cautelar para esse carro!</TextInfo>
          <DownloadButton
            onClick={() => {
              setOpen(!open);
            }}
          >
            Baixar agora
          </DownloadButton>
        </>
      </CautelarContainer>
    ) : (
      <GenerateContainer>
        <ValueContainer>
          <img className="icon" src={CautelarIcon} alt="ícone" style={{ paddingRight: 10 }} />
          <Text>Perícia Cautelar</Text>
        </ValueContainer>

        {pdfLink.linkCautelar !== null && releaseDownloadDoc ? (
          <DownloadContainer href={pdfLink.linkCautelar} target="_blank">
            <img className="icon" src={DownloadIcon} alt="ícone" />
            <DownloadText>Baixar</DownloadText>
          </DownloadContainer>
        ) : (
          <WaitContainer>
            <img className="icon" src={WaitIcon} alt="ícone" />
            <WaitText>Aguardando</WaitText>
          </WaitContainer>
        )}
      </GenerateContainer>
    );
  };

  const EcvMobile = () => {
    return (
      <GenerateContainer style={{ marginTop: 15 }}>
        <ValueContainer>
          <img className="icon" src={CautelarIcon} alt="ícone" style={{ paddingRight: 10 }} />
          <Text>ECV</Text>
        </ValueContainer>

        <DownloadContainer href={pdfLink.linkEcv} target="_blank">
          <img className="icon" src={DownloadIcon} alt="ícone" />
          <DownloadText>Baixar</DownloadText>
        </DownloadContainer>
      </GenerateContainer>
    );
  };

  return alreadyExist !== undefined ? (
    <>
      {!isMobile ? (
        <Container>
          <AdditionalServiceText>
            <AdditionalServiceTitle>Serviços adicionais</AdditionalServiceTitle>
            <AdditionalServiceSubTitle>
              Você pode adquirir serviços adicionais para esse carro.
            </AdditionalServiceSubTitle>
          </AdditionalServiceText>
          <AdditionalServiceOptionsContainer>
            {AdditionaServicesEnum.filter(service => service.showCard).map(service => (
              <AdditionalServiceOptions
                isActive={
                  respondeCreatDoc !== undefined || alreadyExist.exists || service.key === 'ECV'
                }
                key={service.key}
                onClick={() => {
                  respondeCreatDoc === undefined &&
                    !alreadyExist.exists &&
                    service.key === 'CAUTELAR' &&
                    setOpen(!open);
                }}
              >
                <img className="icon" src={service.icon} alt="ícone" style={{ paddingRight: 8 }} />
                <ValueInfoContainer>
                  <AdditionalName>{service.title}</AdditionalName>
                  {respondeCreatDoc === undefined &&
                  !alreadyExist.exists &&
                  service.key !== 'ECV' ? (
                    <AdditionalValue>R$ {formatPrice(service.value)}</AdditionalValue>
                  ) : (service.link !== null && releaseDownloadDoc) || service.key === 'ECV' ? (
                    <DownloadContainer
                      href={service.link}
                      target="_blank"
                      onClick={() => {
                        service.key === 'ECV' && handleSegment('ECV', service.link);
                      }}
                    >
                      <img className="icon" src={DownloadIcon} alt="ícone" />
                      <DownloadText>Baixar</DownloadText>
                    </DownloadContainer>
                  ) : (
                    service.key !== 'ECV' && (
                      <WaitContainerDesk>
                        <img className="icon" src={WaitIcon} alt="ícone" />
                        <WaitText>Aguardando</WaitText>
                      </WaitContainerDesk>
                    )
                  )}
                </ValueInfoContainer>
              </AdditionalServiceOptions>
            ))}
          </AdditionalServiceOptionsContainer>
        </Container>
      ) : (
        <>
          <CautelarMobile />
          {serviceCreated?.ecv && <EcvMobile />}
        </>
      )}
      <Modal
        isOpen={open}
        style={customStyles}
        shouldFocusAfterRender
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        shouldReturnFocusAfterClose
      >
        <CloseIconContainer>
          <CloseIconDiv
            onClick={() => {
              setOpen(!open);
            }}
          >
            <img className="icon" src={CloseIcon} alt="ícone" />
          </CloseIconDiv>
        </CloseIconContainer>

        <ModalContent>
          <ModalTitleContainer>
            <ModalTitle>Confirmar solicitação de serviços adicionais </ModalTitle>
          </ModalTitleContainer>

          <SubTitleModalContainer>
            <SubTitleModal>
              Deseja confirmar a solicitação de Perícia Cautelar{' '}
              <BoldText>no valor de R$ 90,00?</BoldText>
            </SubTitleModal>
          </SubTitleModalContainer>

          <ConfirmButtonContainer>
            <ConfirmButton
              onClick={() => {
                setOpen(!open);
                handleSegment('Cautelar', pdfLink.linkCautelar);
                fetchData('cautelar');
              }}
            >
              <ConfirmButtonText>Confirmar solicitação</ConfirmButtonText>
            </ConfirmButton>
          </ConfirmButtonContainer>

          <CancelTextContainer
            onClick={() => {
              setOpen(!open);
            }}
          >
            <CancelText>Cancelar</CancelText>
          </CancelTextContainer>
        </ModalContent>
      </Modal>
    </>
  ) : null;
};
