import { useCallback, useMemo } from 'react';
import { useFilter } from './useFilter';
import { useSelector } from 'react-redux';
import { useUserProfileContext } from '../context/UserProfileContext';

const url = window.location.href;
const path = window.location.pathname;

export const useSegment = (isRecommended = false, isRecommendedByDetails = false) => {
  const { filterList } = useFilter();

  const applyedFilter = useSelector(state => state.ui.filters.newOffersFiltersApplyed);
  const { profile } = useSelector(state => state.user.profile || {});
  const { user } = useSelector(state => state.authentication);
  const { preferences: userPreferences } = useSelector(state => state.orderCars.userPreferences);
  const { currentUserProfile } = useUserProfileContext();

  const setUser = {
    userId: user?.id,
    dealershipName: user?.dealershipName,
    email: user?.email,
    phoneNumber: currentUserProfile?.phoneNumber,
    relatedNinja: profile?.relatedNinja,
  };

  const formatSections = useMemo(() => {
    if (applyedFilter) return 'conventionalFilter';
    else if (!!filterList?.length) return 'categories';
    else if (isRecommendedByDetails) return 'recomendados';
    else if (isRecommended) return 'orderCars';
    else 'newOffers';
  }, [applyedFilter, filterList, isRecommended, isRecommendedByDetails]);

  const page = useCallback(
    title => {
      analytics.page(title, {
        user: setUser,
        title,
        url,
        path,
      });
    },
    [setUser]
  );

  const trackViewedDetails = useCallback(
    (inspection_id, auction_id, section = formatSections) => {
      analytics.track('Details Viewed', {
        user: setUser,
        inspection_id,
        auction_id,
        section,
      });
    },
    [formatSections, setUser]
  );

  const trackButtonClicked = useCallback(
    (inspection_id, type, auction_id, section = formatSections, date_hour) => {
      analytics.track('Bid Clicked', {
        user: setUser,
        inspection_id,
        type,
        auction_id,
        section,
        date_hour,
      });
    },
    [formatSections, setUser]
  );

  const trackConfirmBid = useCallback(
    props => {
      props.user = setUser;
      if (typeof props?.section === 'undefined') props.section = formatSections;
      analytics.track('Bid Confirmed', props);
    },
    [formatSections, setUser]
  );

  const trackCarouselInteraction = useCallback(
    (inspection_id, auction_id, section = formatSections) => {
      analytics.track('Carousel Interaction', {
        user: setUser,
        inspection_id,
        auction_id,
        section,
      });
    },
    [formatSections, setUser]
  );

  const trackFilter = useCallback(
    (type, criteria, results) => {
      analytics.track('Filter', {
        user: setUser,
        type,
        criteria,
        results,
      });
    },
    [setUser]
  );

  const trackRemoveAutoBid = useCallback(
    (inspection_id, autobid_id, auction_id, section = formatSections) => {
      analytics.track('Automatic Offer Removed', {
        user: setUser,
        inspection_id,
        autobid_id,
        auction_id,
        section,
      });
    },
    [setUser, formatSections]
  );

  const trackChangeMaximumAutoBid = useCallback(
    (inspection_id, autobid_id, new_bid_value, last_bid_value, section = formatSections) => {
      analytics.track('Automatic Offer Maximum Bid Changed', {
        user: setUser,
        inspection_id,
        autobid_id,
        new_bid_value,
        last_bid_value,
        section,
      });
    },
    [setUser, formatSections]
  );

  const trackTieAutoBid = useCallback(
    (inspection_id, autobid_id, bidAmount, section = formatSections) => {
      analytics.track('Automatic Offer Tied', {
        user: setUser,
        inspection_id,
        autobid_id,
        bidAmount,
        section,
      });
    },
    [setUser, formatSections]
  );

  const trackReachedLimitAutoBid = useCallback(
    (inspection_id, autobid_id, auction_id, section = formatSections) => {
      analytics.track('Automatic Offer Limit Recheaded', {
        user: setUser,
        inspection_id,
        autobid_id,
        auction_id,
        section,
      });
    },
    [setUser, formatSections]
  );

  const trackFinCredit = useCallback(
    (dateHour, section) => {
      analytics.track('Financing Tracker', {
        user: setUser,
        date_hour: dateHour,
        section,
      });
    },
    [setUser, formatSections]
  );

  const trackQuickButtons = useCallback(
    props => {
      props.user = setUser;
      if (typeof props?.section === 'undefined') props.section = formatSections;
      analytics.track('Quick Button', props);
    },
    [formatSections, setUser]
  );

  const trackAdditionalService = useCallback(
    (date_hour, inspection_id, type, year, make, model, winningBid, link_pdf) => {
      analytics.track('Additional Service', {
        user: setUser,
        date_hour,
        inspection_id,
        type,
        year,
        make,
        model,
        winningBid,
        link_pdf,
      });
    },
    [formatSections, setUser]
  );

  return {
    page,
    trackViewedDetails,
    trackButtonClicked,
    trackConfirmBid,
    trackCarouselInteraction,
    trackFilter,
    trackRemoveAutoBid,
    trackChangeMaximumAutoBid,
    trackTieAutoBid,
    trackReachedLimitAutoBid,
    trackQuickButtons,
    trackFinCredit,
    trackAdditionalService,
  };
};
