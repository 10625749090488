import React, { useCallback, useMemo, useState, useEffect, memo } from 'react';

import { TiLocation } from 'react-icons/ti';
import FaHeart from '../../../images/icons/favoritedFillIcon.svg';
import FaRegHeart from '../../../images/icons/favoritedOutlineIcon.svg';

import {
  Container,
  VehicleCarouselPhotoContainer,
  Content,
  Row,
  VehicleNameAndModel,
  FavoriteButton,
  VehicleDescription,
  VehicleApprovalAndLocation,
  VehicleApprovalLabel,
  VehicleLocationContainer,
  VehicleLocationLabel,
  ShippingPopover,
  LastBidContainer,
  LastBidLabel,
  LastBidValue,
  ActionsContainer,
  TypeOffersActions,
  VehicleRestrictions,
  BuyForButton,
  VehicleFipeTag,
  Box,
} from './styles';
import { CarouselPhoto } from '../../CarouselPhoto';
import { useBid } from '../../../hooks/useBid';
import {
  TimingAuctionContextProvider,
  useTimingAuctionContext,
} from '../../../context/TimingAuctionContext';
import { AuctionItemTimer } from '../../enhanced';
import { useUserFavoriteVehicle } from '../../../context/FavoriteContext';
import Modal from '../../enhanced/Modal';
import { Dialog } from '../../dialog';
import { useHistory } from 'react-router-dom';
import {
  AUCTION_OFFER_KEY,
  AUCTION_BUY_KEY,
  AUCTION_AUTOMATIC_OFFER_KEY,
  AUCTION_BUY_FOR,
  AUCTION_BUY_KEY_CONFIRMATION,
} from '../../Modals';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleModal } from '../../../store/actions/ui.actions';
import { setSelectedAuction } from '../../../store/actions/auction.actions';
import { gtmEvents } from '../../../utils/gtmTracker';
import { trackAuctionEvent } from '../../../store/actions/event-tracking.actions';
import { useFlag } from '@unleash/proxy-client-react';
import { useFilter } from '../../../hooks/useFilter';
import { sendEvent } from '../../../helpers/googleAnalytics';
import { useSegment } from '../../../hooks/useSegment';
import { formatPrice } from '../../../utils/formatPrice';
import { Button } from '../../Buttons';
import { PercentageTag } from '../../PercentageTag';
import { MdSchedule } from 'react-icons/md';
import EvaluationBadges from '../../EvaluationBadges';

const AuctionNewOffersCard = memo(
  ({
    auction,
    toggleModal,
    selectAuction,
    trackAuctionEventAction,
    previousCard,
    isHighlight = false,
    isRecommended = false,
    isRecommendedByDetails = false,
    isFromFavorite = false,
  }) => {
    const { currentOffer, currentOfferLabel } = useBid(auction);
    const { isFavorite, addFavorite, removeFavorite } = useUserFavoriteVehicle(auction._id);
    const { milisecondsLeft } = useTimingAuctionContext();
    const { filterList } = useFilter();
    const { user } = useSelector(state => state.authentication);
    const enabled = useFlag('auto-bid');
    const enabledPrices = useFlag('fipe-web-prices');
    const enabledMaximumQuality = useFlag('maximum-quality') && auction?.summary?.maximumQuality;
    const enabledScore = useFlag('score');
    const hasFinished = auction.auction.status === 'FINISHED';

    const { trackButtonClicked, trackViewedDetails, trackCarouselInteraction } = useSegment(
      isRecommended,
      isRecommendedByDetails
    );
    const { preferences: userPreferences } = useSelector(state => state.orderCars.userPreferences);

    const [showFavoriteModal, setShowFavoriteModal] = useState(false);
    const [showShippingPopover, setShowShippingPopover] = useState(false);

    const trackCategoryInteraction = interactionType => {
      if (!!filterList.length) {
        sendEvent('categoriesTracker', {
          dealershipId: user.id,
          dealershipEmail: user.email,
          dealershipName: user.dealershipName,
          selectedCategory: filterList.join(', '),
          interactionType: interactionType,
          inspectionId: auction._id,
          eventCategory: 'Auctions',
          eventAction: 'Interaction',
          eventLabel: 'Categorias',
        });
      }
    };

    const trackInteractionPreferences = interactionType => {
      if (isRecommended) {
        sendEvent('preferencesTracker', {
          dealershipId: user?.id,
          dealershipEmail: user?.email,
          dealershipName: user.dealershipName,
          inspectionId: auction._id,
          selectedCategory: Object.keys(userPreferences)?.filter(val => userPreferences[val]),
          interactionType: interactionType,
          eventCategory: 'Auctions',
          eventAction: 'Interaction',
          eventLabel: 'Pedir Carros',
        });
      }

      if (isRecommendedByDetails) {
        sendEvent('preferencesTracker', {
          dealershipId: user?.id,
          dealershipEmail: user?.email,
          dealershipName: user.dealershipName,
          inspectionId: auction._id,
          interactionType: interactionType,
          eventCategory: 'Auctions',
          eventAction: 'Interaction',
          eventLabel: 'Recomendados',
        });
      }
    };

    const trackDetails = () => {
      trackInteractionPreferences('details');
      trackCategoryInteraction('details');
      trackViewedDetails(auction?._id, auction?.auction?.idAuction);
    };

    const trackBid = () => {
      trackInteractionPreferences('bid');
      trackCategoryInteraction('bid');
    };

    const trackCarousel = () => {
      trackInteractionPreferences('carousel');
      trackCategoryInteraction('carousel');
      trackCarouselInteraction(auction?._id, auction?.auction?.idAuction);
    };

    const vehicleRestrictions = useMemo(
      () => auction?.summary?.documentResearch?.historics?.join(', '),
      [auction?.summary?.documentResearch?.historics]
    );

    const vehiclePercentageFipe = useMemo(() => {
      if (!!auction?.summary?.marketPrice && auction?.summary?.marketPrice > 100) {
        return Math.trunc((100 * currentOffer) / auction?.summary?.marketPrice) || 0;
      } else {
        return 0;
      }
    }, [auction?.summary?.marketPrice, currentOffer]);

    const handleAddingFavoriteAuction = useCallback(() => {
      if (isFavorite) return setShowFavoriteModal(true);

      addFavorite();
    }, [isFavorite, addFavorite]);

    const handleRemovingFavoriteAuction = useCallback(() => {
      removeFavorite();
      setShowFavoriteModal(false);
    }, [removeFavorite]);

    const handleSingleOfferModal = useCallback(() => {
      selectAuction(auction, { remainingTime: milisecondsLeft });
      toggleModal({
        key: AUCTION_OFFER_KEY,
        show: true,
        extras: { isRecommended, isRecommendedByDetails },
      });
      trackAuctionEventAction(gtmEvents.CTA_MAKE_OFFER_LISTING, auction._id);
      trackButtonClicked(auction._id, 'singleBid', auction.auction.idAuction);
    }, [auction, toggleModal, trackAuctionEventAction, milisecondsLeft, trackButtonClicked]);

    const handleAutomaticOfferModal = useCallback(() => {
      selectAuction(auction, { remainingTime: milisecondsLeft });
      toggleModal({
        key: AUCTION_AUTOMATIC_OFFER_KEY,
        show: true,
        extras: { selectedAuction: auction, isRecommended, isRecommendedByDetails },
      });
      trackButtonClicked(auction._id, 'autoBid', auction.auction.idAuction);
    }, [auction, toggleModal, milisecondsLeft, trackButtonClicked]);

    const handleNegotiateExclusivityModal = useCallback(() => {
      selectAuction(auction, { remainingTime: milisecondsLeft });
      toggleModal({
        key: AUCTION_BUY_KEY,
        show: true,
        extras: { isRecommended, isRecommendedByDetails },
      });
      trackButtonClicked(auction._id, 'exclusivityBid', auction.auction.idAuction);
    }, [selectAuction, auction, milisecondsLeft, toggleModal, trackButtonClicked]);

    const handleBuyForModal = useCallback(() => {
      selectAuction(auction, { remainingTime: milisecondsLeft });
      toggleModal({
        key: AUCTION_BUY_FOR,
        show: true,
        extras: { isRecommended, isRecommendedByDetails },
      });
      trackButtonClicked(auction._id, 'buyForBid', auction.auction.idAuction);
    }, [selectAuction, auction, milisecondsLeft, toggleModal, trackButtonClicked]);

    const useTagLayout = useCallback(() => {
      if (enabledPrices) {
        return <PercentageTag price={auction?.summary?.marketPrice} currentOffer={currentOffer} />;
      } else if (!enabledPrices && !!vehiclePercentageFipe) {
        return <VehicleFipeTag>{vehiclePercentageFipe}% da Fipe</VehicleFipeTag>;
      }
    }, [enabledPrices, auction?.summary?.marketPrice, currentOffer, vehiclePercentageFipe]);

    useEffect(() => {
      if (previousCard) {
        previousCard(auction?._id);
      }
    }, [previousCard, auction?._id]);

    const smallerSize = auction?.summary?.buyForPrice?.enabled
      ? auction?.summary?.buyForPrice?.amount.toString().length > 6
      : auction?.summary?.buynowPrice?.amount.toString().length > 6;

    return (
      <TimingAuctionContextProvider auction={auction?.auction}>
        <Container
          id={auction?._id}
          isHighlight={isHighlight}
          disable={hasFinished}
          isFromFavorite={isFromFavorite}
        >
          {useTagLayout()}
          <VehicleCarouselPhotoContainer>
            <CarouselPhoto
              onClickSlide={() => {
                trackDetails();
                toggleModal({
                  key: AUCTION_BUY_KEY_CONFIRMATION,
                  show: false,
                });
                toggleModal({
                  key: AUCTION_BUY_FOR,
                  show: false,
                });
                toggleModal({
                  key: AUCTION_OFFER_KEY,
                  show: false,
                });
                toggleModal({
                  key: AUCTION_AUTOMATIC_OFFER_KEY,
                  show: false,
                });
              }}
              photos={auction?.summary?.photos}
              arrows
              dots
              counter
              trackCarousel={trackCarousel}
              href={`/detalhes/${auction?._id}`}
              showMaximumQualitySeal={enabledMaximumQuality}
            />
          </VehicleCarouselPhotoContainer>

          <Content>
            <Row>
              <VehicleNameAndModel
                onClick={() => {
                  trackDetails();
                  toggleModal({
                    key: AUCTION_BUY_KEY_CONFIRMATION,
                    show: false,
                  });
                  toggleModal({
                    key: AUCTION_BUY_FOR,
                    show: false,
                  });
                  toggleModal({
                    key: AUCTION_OFFER_KEY,
                    show: false,
                  });
                  toggleModal({
                    key: AUCTION_AUTOMATIC_OFFER_KEY,
                    show: false,
                  });
                }}
                to={`/detalhes/${auction?._id}`}
              >
                {auction?.summary?.make} {auction?.summary?.model}
              </VehicleNameAndModel>
              <FavoriteButton onClick={handleAddingFavoriteAuction}>
                {isFavorite ? <img src={FaHeart} /> : <img src={FaRegHeart} />}
              </FavoriteButton>
            </Row>
            <VehicleDescription>
              {auction?.summary?.yearLabel} - {auction?.summary?.version}{' '}
              {auction?.summary?.kmLabel}
            </VehicleDescription>

            <VehicleApprovalAndLocation enabledScore={enabledScore}>
              {/*               {enabledScore ? (
                <VehicleApprovalLabel
                  onClick={() => trackDetails()}
                  to={`/detalhes/${auction?._id}`}
                >
                  <strong>{Math.round(auction?.summary?.goodPointsCount || 0)}%</strong> dos pontos
                  aprovados.
                </VehicleApprovalLabel>
              ) : null} */}

              <VehicleLocationContainer
                onClick={() => setShowShippingPopover(!showShippingPopover)}
              >
                <TiLocation
                  size={27}
                  color="#22aa52"
                  style={{ verticalAlign: 'text-top', marginRight: '0.3em' }}
                />
                <VehicleLocationLabel>
                  {auction?.summary?.vehicleYardRegion ?? auction?.summary?.state}
                </VehicleLocationLabel>
                {showShippingPopover && (
                  <ShippingPopover>
                    O carro será disponibilizado no pátio desta localidade.
                  </ShippingPopover>
                )}
              </VehicleLocationContainer>
              <EvaluationBadges auction={auction} />
            </VehicleApprovalAndLocation>
            {hasFinished && isFromFavorite && (
              <Box>
                <MdSchedule />
                <span>leilão encerrado</span>
              </Box>
            )}

            {!hasFinished && (
              <>
                <AuctionItemTimer showBuyNowButton={false} auction={auction} />
                <LastBidContainer>
                  <LastBidLabel>Lance atual</LastBidLabel>
                  <LastBidValue>
                    R$ <strong style={{ fontSize: '1.5rem' }}>{currentOfferLabel}</strong>
                  </LastBidValue>
                </LastBidContainer>

                {user.isNinja() || !enabled ? (
                  <ActionsContainer onClick={trackBid}>
                    <Button hover="#4ab971" borderRadius={8} onClick={handleSingleOfferModal}>
                      Oferta avulsa
                    </Button>

                    {!auction?.summary?.buyForPrice?.enabled &&
                      auction?.summary?.buynowPrice?.enabled && (
                        <Button
                          fontSize={smallerSize ? 14.5 : 16}
                          fontWeight={400}
                          bg="#ff4c00"
                          hover="#ff7941"
                          borderRadius={8}
                          onClick={handleNegotiateExclusivityModal}
                        >
                          Negocie a partir de{' '}
                          <strong>R$ {formatPrice(auction?.summary?.buynowPrice?.amount)}</strong>
                        </Button>
                      )}

                    {auction?.summary?.buyForPrice?.enabled && (
                      <BuyForButton smallerSize={smallerSize} onClick={handleBuyForModal}>
                        Compra garantida por{' '}
                        <strong>R$ {formatPrice(auction?.summary?.buyForPrice?.amount)}</strong>
                      </BuyForButton>
                    )}
                  </ActionsContainer>
                ) : (
                  <ActionsContainer onClick={trackBid}>
                    <TypeOffersActions>
                      <Button hover="#4ab971" borderRadius={8} onClick={handleSingleOfferModal}>
                        Oferta avulsa
                      </Button>

                      <Button hover="#4ab971" borderRadius={8} onClick={handleAutomaticOfferModal}>
                        Automática
                      </Button>
                    </TypeOffersActions>

                    {!auction?.summary?.buyForPrice?.enabled &&
                      auction?.summary?.buynowPrice?.enabled && (
                        <Button
                          fontSize={smallerSize ? 14.5 : 16}
                          fontWeight={400}
                          bg="#ff4c00"
                          hover="#ff7941"
                          borderRadius={8}
                          onClick={handleNegotiateExclusivityModal}
                        >
                          Negocie a partir de{' '}
                          <strong>R$ {formatPrice(auction?.summary?.buynowPrice?.amount)}</strong>
                        </Button>
                      )}

                    {auction?.summary?.buyForPrice?.enabled && (
                      <BuyForButton smallerSize={smallerSize} onClick={handleBuyForModal}>
                        Compra garantida por{' '}
                        <strong>R$ {formatPrice(auction?.summary?.buyForPrice?.amount)}</strong>
                      </BuyForButton>
                    )}
                  </ActionsContainer>
                )}
              </>
            )}

            <VehicleRestrictions>
              <strong>Restrições: </strong>
              {vehicleRestrictions ?? 'Nada Consta'}.
            </VehicleRestrictions>
          </Content>
          <Modal show={showFavoriteModal}>
            <Dialog
              header="Deseja remover esse veículo dos seus favoritos?"
              onCTAClick={handleRemovingFavoriteAuction}
              onSecondaryBtnClick={() => setShowFavoriteModal(false)}
              primaryBtnLabel="Sim, remover"
              primaryBtnVariant="warning"
              secondaryBtnLabel="Não remover"
              secondaryBtnVariant="ghost.warning"
            />
          </Modal>
        </Container>
      </TimingAuctionContextProvider>
    );
  }
);

function mapDispatchToProps(dispatch) {
  return {
    selectAuction: bindActionCreators(setSelectedAuction, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    trackAuctionEventAction: trackAuctionEvent,
  };
}

export default connect(null, mapDispatchToProps)(AuctionNewOffersCard);
