import React from 'react';
import { toPrice } from '../../utils/number';
import IconWaiting from '../../images/icons/iconWaiting.svg';
import IconCheck from '../../images/icons/iconCheck.svg';
import IconError from '../../images/icons/iconError.svg';

export const STATUS = {
  ON_OFFER: { id: 0, key: 'ON_OFFER' },

  OFFER_ACCEPTED: { id: 1, key: 'OFFER_ACCEPTED' },

  OFFER_DECLINED: { id: 2, key: 'OFFER_DECLINED' },

  TICKET_SENT: { id: 3, key: 'TICKET_SENT' },

  PAID: { id: 4, key: 'PAID' },

  BOUGHT_CAR: { id: 5, key: 'BOUGHT_CAR' },

  CAR_DELIVERED_BY_SELLER: { id: 6, key: 'CAR_DELIVERED_BY_SELLER' },

  WAITING_RELEASE_CAR: { id: 7, key: 'WAITING_RELEASE_CAR' },

  WAITING_RELEASE_CAR_BLOCKED: { id: 8, key: 'WAITING_RELEASE_CAR_BLOCKED' },

  WAITING_RELEASE_CAR_DATE_CHANGE: { id: 9, key: 'WAITING_RELEASE_CAR_DATE_CHANGE' },

  WAITING_WITHDRAWAL: { id: 10, key: 'WAITING_WITHDRAWAL' },

  DELIVERED: { id: 11, key: 'DELIVERED' },

  DOCUMENTATION_IN_PROGRESS: { id: 12, key: 'DOCUMENTATION_IN_PROGRESS' },

  DOCUMENTATION_READY: { id: 13, key: 'DOCUMENTATION_READY' },

  RETURNED: { id: 14, key: 'RETURNED' },

  CREATED_CAUTELAR: { id: 15, key: 'CREATED_CAUTELAR' },

  CREATED_ECV: { id: 16, key: 'CREATED_ECV' },
};

const defaultEvents = [
  {
    auctionId: '',
    user: '',
    status: STATUS.ON_OFFER.key,
    updatedAt: null,
    timestamp: 0,
  },
];

export const formatPurchased = (arr = []) =>
  arr.map(
    ({
      vsrId,
      id,
      negotiationStartedAt,
      winningBid,
      negotiations,
      slug,
      photo,
      usedUserTaxId,
      year,
      make,
      model,
    }) => {
      const events = FormatEvents({
        negotiations,
        winningBid,
        negotiationStartedAt,
        id: vsrId,
        year,
        make,
        model,
      });
      return {
        slug,
        src: photo,
        step: events.active ? Math.ceil(events.active.id / 2) : 1,
        events,
        id,
        usedUserTaxId,
        vsrId,
        winningBid,
        year,
        make,
        model,
      };
    }
  );

export const FormatEvents = ({
  negotiations = defaultEvents,
  winningBid,
  negotiationStartedAt,
  id,
}) => {
  let events = negotiations.length > 0 ? negotiations : defaultEvents;

  const lastEvent = events[0];

  let lastUpdated = negotiationStartedAt;

  let activeEvent = null;

  const isComplete = (arr = []) =>
    arr.some(status => STATUS[lastEvent.status]?.id > STATUS[status]?.id);

  const isActive = (arr = []) => arr.some(status => status === lastEvent.status);

  const filterDone = isActive([
    STATUS.RETURNED.key,
    STATUS.OFFER_DECLINED.key,
    STATUS.DOCUMENTATION_READY.key,
  ])
    ? 1
    : 0;

  const getUpdateTime = statuses => {
    for (const event of events) {
      if (statuses.includes(event.status)) {
        return event.updatedAt;
      }
    }
    return null;
  };

  const getDateChangeReason = statuses => {
    for (const event of events) {
      if (statuses.includes(event.status)) {
        return event.reasonBlocked;
      }
    }
    return null;
  };

  const getDueDate = (statuses, includeYear) => {
    for (const event of events) {
      if (statuses.includes(event.status)) {
        const dueDate = event.dueDate;

        const partes = dueDate.split('-');

        const ano = partes[0];
        const mes = partes[1];
        const dia = partes[2];

        if (includeYear) {
          return `${dia}/${mes}/${ano}`;
        } else {
          return `${dia}/${mes}`;
        }
      }
    }
    return null;
  };

  const getReasonDeclined = statuses => {
    let reasonDeclinedTranslate = '';

    for (const event of events) {
      if (statuses.includes(event.status)) {
        const reasonDeclined = event?.reasonBlocked?.toLowerCase();

        if (reasonDeclined === 'ecv bloqueado') {
          reasonDeclinedTranslate =
            'Será preciso aguardar o Laudo ECV. O prazo para liberação do carro é até ';
        } else if (reasonDeclined === 'conserto') {
          reasonDeclinedTranslate =
            'O veículo está passando por reparos para ser entregue nas condições adequadas. O prazo para liberação é até ';
        } else if (reasonDeclined === '2 a via do dut') {
          reasonDeclinedTranslate =
            'A 2ª Via do DUT está quase pronta! O prazo de liberação é até ';
        } else if (reasonDeclined === 'regularização do motor') {
          reasonDeclinedTranslate =
            'Será necessário regularizar documentação de motor com transferência para liberar o carro. O prazo de liberação é até ';
        }
      }
    }

    return reasonDeclinedTranslate;
  };

  const getOpeningVenue = statuses => {
    for (const event of events) {
      if (statuses.includes(event.status)) {
        return event?.addressWithdrawal?.opening.toLowerCase();
      }
    }
    return null;
  };

  const hasAlreadyHappened = statuses => {
    for (const event of events) {
      if (statuses.includes(event.status)) {
        return true;
      }
    }
    return false;
  };

  const getActiveGroup = statuses => {
    for (const event of events) {
      if (statuses.includes(event.group)) {
        return true;
      }
    }
    return false;
  };

  const getVenueLocation = statuses => {
    for (const event of events) {
      if (statuses.includes(event.status)) {
        return event?.addressWithdrawal?.address;
      }
    }
    return null;
  };

  const plateNumber = statuses => {
    for (const event of events) {
      if (statuses.includes(event.status)) {
        return event?.plateNumber;
      }
    }
    return null;
  };

  const pdfLink = statuses => {
    for (const event of events) {
      if (statuses.includes(event.status)) {
        return event?.url;
      }
    }
    return null;
  };

  const withdrawalSub = (
    <>
      <span>
        Informe a placa <strong>{plateNumber([STATUS.WAITING_WITHDRAWAL.key])}</strong> na retirada.
      </span>
      <br />
      <br />
      <span>
        Retirada de {getOpeningVenue([STATUS.WAITING_WITHDRAWAL.key])} em{' '}
        <strong>{getVenueLocation([STATUS.WAITING_WITHDRAWAL.key])}</strong>
      </span>
    </>
  );

  const states = [
    {
      id: 1,
      label: 'Ganhou a disputa!',
      pendingMsg: { long: 'Aguardando', short: 'Aguardando' },
      completeMsg: {
        long: `Você ganhou a disputa com o lance vencedor de ${toPrice(winningBid)}`,
      },
      complete: true,
      data: { dataLabel: 'Ver avaliação', dataType: 'url', value: `/detalhes/${id}` },
      updateTime: getUpdateTime([STATUS.ON_OFFER.key]),
      showLine: true,
    },

    {
      id: 2,
      label: 'Envio da oferta',
      pendingMsg: {
        long: 'Sua oferta foi enviada ao proprietário, ele tem até 2 dias úteis para retornar.',
        short: 'Aguardando vendedor',
      },
      doneMsg: {
        short: 'Proposta enviada',
        long: 'Sua oferta foi enviada ao proprietário, ele tem até 2 dias úteis para retornar.',
      },
      done: isComplete([STATUS.ON_OFFER.key]),
      active: isActive([STATUS.ON_OFFER.key]),
      icon: IconWaiting,
      updateTime: getUpdateTime([STATUS.OFFER_ACCEPTED.key, STATUS.OFFER_DECLINED.key]),
      showLine: true,
    },

    {
      id: 3,
      label: isActive([STATUS.OFFER_DECLINED.key]) ? 'Oferta não aceita!' : 'Oferta aceita',
      pendingMsg: { long: 'Aguardando', short: 'Aguardando' },
      doneMsg: {
        long: 'Proposta aceita! Em breve a fatura estará disponível para pagamento.',
        short: 'Proposta aceita',
      },
      done: isActive([STATUS.OFFER_DECLINED.key]) ? false : isComplete([STATUS.ON_OFFER.key]),
      active: isActive([STATUS.OFFER_ACCEPTED.key, STATUS.OFFER_DECLINED.key]),
      errorMsg: {
        long: 'Não foi dessa vez... Infelizmente o proprietário não aceitou a sua oferta.',
        short: 'Oferta não aceita',
      },
      error: isActive([STATUS.OFFER_DECLINED.key]),
      updateTime: getUpdateTime([STATUS.OFFER_ACCEPTED.key, STATUS.OFFER_DECLINED.key]),
      showLine: true,
    },

    {
      id: 4,
      label: 'Pagamento',
      subMsg: [
        {
          msg: { main: 'Pagamento recebido' },
          active: hasAlreadyHappened([STATUS.PAID.key]),
          updateTime: getUpdateTime([STATUS.PAID.key]),
        },
      ],
      pendingMsg: {
        long: 'Disponível para pagamento',
        short: 'Disponível para pagamento',
      },
      doneMsg: { long: 'Disponível para pagamento', short: 'Pgamento Recebido' },
      active: getActiveGroup(['payment']),
      done: hasAlreadyHappened([STATUS.PAID.key]),
      data: {
        dataLabel: 'Pagamento facilitado em 6x',
        dataType: 'url',
        value: `/pagamento-facilitado/`,
        secondValue: `https://portal.omie.com.br/historico-financeiro`,
      },
      updateTime: getUpdateTime([STATUS.TICKET_SENT.key]),
      showLine: true,
    },

    {
      id: 5,
      label: 'Aguardando liberação do carro',
      pendingMsg: {
        long: `Estamos aguardando o proprietário realizar a entrega do veículo para a InstaCarro, ele tem até ${getDueDate(
          [STATUS.BOUGHT_CAR.key],
          false
        )} para isso.`,
        short: isActive([STATUS.WAITING_RELEASE_CAR_DATE_CHANGE.key])
          ? 'Alteração no prazo de liberação'
          : 'Aguardando liberação',
      },
      doneMsg: {
        long: `Estamos aguardando o proprietário realizar a entrega do veículo para a InstaCarro, ele tem até ${getDueDate(
          [STATUS.BOUGHT_CAR.key],
          false
        )} para isso.`,
        short:
          hasAlreadyHappened([STATUS.WAITING_WITHDRAWAL.key]) &&
          !hasAlreadyHappened([STATUS.DELIVERED.key])
            ? 'Pronto para retirada'
            : 'Carro retirado',
      },

      done: hasAlreadyHappened([STATUS.WAITING_WITHDRAWAL.key, STATUS.DELIVERED.key]),

      active: getActiveGroup(['logistic']),

      showDeliveredSchedule:
        hasAlreadyHappened([STATUS.WAITING_WITHDRAWAL.key]) &&
        !hasAlreadyHappened([STATUS.DELIVERED.key]),

      subMsg: [
        {
          msg: {
            main: 'Carro entregue à InstaCarro',
            sub:
              'O carro foi entregue para a InstaCarro, agora falta pouco! Em breve o prazo para retirada estará disponível.',
          },
          active: hasAlreadyHappened([STATUS.CAR_DELIVERED_BY_SELLER.key]),
          updateTime: getUpdateTime([STATUS.CAR_DELIVERED_BY_SELLER.key]),
          hideMessage:
            isActive([STATUS.WAITING_RELEASE_CAR_DATE_CHANGE.key]) ||
            (isActive([STATUS.WAITING_RELEASE_CAR_DATE_CHANGE.key])
              ? isComplete([STATUS.WAITING_RELEASE_CAR_DATE_CHANGE.key])
              : isActive([STATUS.WAITING_RELEASE_CAR_BLOCKED.key])
              ? isComplete([STATUS.WAITING_RELEASE_CAR_BLOCKED.key])
              : isComplete([STATUS.WAITING_RELEASE_CAR.key])),
        },
        {
          msg: {
            main: 'Prazo de entrega',
            sub: isActive([STATUS.WAITING_RELEASE_CAR_BLOCKED.key])
              ? getReasonDeclined([STATUS.WAITING_RELEASE_CAR_BLOCKED.key]) +
                getDueDate([STATUS.WAITING_RELEASE_CAR_BLOCKED.key], true)
              : `O prazo para liberação do veículo é até ${getDueDate(
                  [STATUS.WAITING_RELEASE_CAR.key, STATUS.WAITING_RELEASE_CAR_BLOCKED.key],
                  true
                )}.`,
          },
          active: hasAlreadyHappened([
            STATUS.WAITING_RELEASE_CAR_BLOCKED.key,
            STATUS.WAITING_RELEASE_CAR.key,
          ]),
          updateTime: getUpdateTime([
            STATUS.WAITING_RELEASE_CAR_BLOCKED.key,
            STATUS.WAITING_RELEASE_CAR.key,
          ]),

          hideMessage:
            isActive([STATUS.WAITING_RELEASE_CAR_DATE_CHANGE.key]) ||
            (isActive([STATUS.WAITING_RELEASE_CAR_DATE_CHANGE.key])
              ? isComplete([STATUS.WAITING_RELEASE_CAR_DATE_CHANGE.key])
              : isActive([STATUS.WAITING_RELEASE_CAR_BLOCKED.key])
              ? isComplete([STATUS.WAITING_RELEASE_CAR_BLOCKED.key])
              : isComplete([STATUS.WAITING_RELEASE_CAR.key])),
        },

        isActive([STATUS.WAITING_RELEASE_CAR_DATE_CHANGE.key]) && {
          msg: {
            main: 'Alteração no prazo',
            sub: getDateChangeReason([STATUS.WAITING_RELEASE_CAR_DATE_CHANGE.key])
              ? `${getDateChangeReason([
                  STATUS.WAITING_RELEASE_CAR_DATE_CHANGE.key,
                ])} O novo prazo de liberação é até dia ${getDueDate(
                  [STATUS.WAITING_RELEASE_CAR_DATE_CHANGE.key],
                  true
                )}.`
              : `Tivemos um imprevisto e o processo está demorando mais do
              que o esperado, o novo prazo de liberação é até dia ${getDueDate(
                [STATUS.WAITING_RELEASE_CAR_DATE_CHANGE.key],
                true
              )}.`,
          },
          active: isActive([STATUS.WAITING_RELEASE_CAR_DATE_CHANGE.key]),
          updateTime: getUpdateTime([STATUS.WAITING_RELEASE_CAR_DATE_CHANGE.key]),
          icon: 'wait',
        },

        {
          msg: {
            main: 'Pronto para retirada',
            sub: withdrawalSub,
            link:
              'https://docs.google.com/forms/d/1go6v-YwNoQ2d5BfQF2C5yQ9Q_Kgq0dNcy2ZsciFrmBo/viewform?edit_requested=true&edit_requested=true&pli=1',
          },
          active: hasAlreadyHappened([STATUS.WAITING_WITHDRAWAL.key]),
          updateTime: getUpdateTime([STATUS.WAITING_WITHDRAWAL.key]),
          hideMessage: isActive([STATUS.RETURNED.key, STATUS.DELIVERED.key]),
          approvalLink: !hasAlreadyHappened([STATUS.DELIVERED.key]) && 'Autorizar retirada',
        },

        hasAlreadyHappened([STATUS.DELIVERED.key]) && {
          msg: { main: 'Carro retirado' },
          active: hasAlreadyHappened([STATUS.DELIVERED.key]),
          updateTime: getUpdateTime([STATUS.DELIVERED.key]),
        },
      ].filter(Boolean),
      updateTime: getUpdateTime([STATUS.BOUGHT_CAR.key]),
      showLine: true,
    },

    {
      id: 6,
      label: isActive([STATUS.RETURNED.key]) ? 'Carro devolvido' : 'Preparando documentação',
      pendingMsg: { short: 'Preparando documentação' },
      doneMsg: { short: 'Documentação enviada' },

      done: hasAlreadyHappened([STATUS.DOCUMENTATION_READY.key]),

      active: getActiveGroup(['documentation']),

      subMsg: isActive([STATUS.RETURNED.key])
        ? []
        : [
            {
              msg: {
                main: 'Aguardando proprietário',
                sub: 'Quase lá! Aguardando o proprietário entregar o documento à InstaCarro.',
              },
              active: hasAlreadyHappened([STATUS.DOCUMENTATION_IN_PROGRESS.key]),
              updateTime: getUpdateTime([STATUS.DOCUMENTATION_IN_PROGRESS.key]),
            },
            {
              msg: {
                main: 'Documentação entregue',
                sub: 'O documento foi entregue para a loja.',
              },
              active: hasAlreadyHappened([STATUS.DOCUMENTATION_READY.key]),
              updateTime: getUpdateTime([STATUS.DOCUMENTATION_READY.key]),
            },
          ],
      updateTime: getUpdateTime([STATUS.DOCUMENTATION_IN_PROGRESS.key, STATUS.RETURNED.key]),
      errorMsg: { long: 'O veículo foi devolvido para a InstaCarro.', short: 'Carro devolvido' },
      error: isActive([STATUS.RETURNED.key]),
    },
  ];

  const newEvents = states.map(
    ({
      completeMsg,
      pendingMsg,
      errorMsg,
      doneMsg,
      error,
      done,
      complete,
      active,
      id,
      updateTime,
      subStatatusActive,
      ...etc
    }) => {
      const event = {
        state: done
          ? 'done'
          : error
          ? 'error'
          : complete
          ? 'complete'
          : active
          ? 'active'
          : 'pending',
        msg: error ? errorMsg : done ? doneMsg : complete ? completeMsg : pendingMsg,
        id,
        updateTime,
        subStatatusActive,
        ...etc,
      };
      event.color = getColor(event.state);
      event.icon = getIcon(event.state);
      if ((active && !activeEvent) || (active && id === 5) || (active && id === 6))
        activeEvent = event;
      return event;
    }
  );

  return {
    active: activeEvent,
    lastUpdated: lastUpdated ? lastUpdated : 'N/A',
    timestamp: lastUpdated ? lastUpdated : 'N/A',
    filterCar: filterDone,
    all: newEvents,
    offerAccepted:
      isComplete([STATUS.ON_OFFER.key]) && !hasAlreadyHappened([STATUS.OFFER_DECLINED.key]),
    releaseDownloadDoc:
      hasAlreadyHappened([STATUS.WAITING_WITHDRAWAL.key]) &&
      hasAlreadyHappened([STATUS.CREATED_CAUTELAR.key]),
    pdfLink: {
      linkCautelar: pdfLink([STATUS.CREATED_CAUTELAR.key]),
      linkEcv: pdfLink([STATUS.CREATED_ECV.key]),
    },
    serviceCreated: {
      ecv:
        hasAlreadyHappened([STATUS.CREATED_ECV.key]) &&
        hasAlreadyHappened([STATUS.WAITING_WITHDRAWAL.key]),
    },
  };
};

export const getColor = str => {
  switch (str) {
    case 'active':
      return {
        light: '#FEFFB2',
        dark: '#FEFFB2',
      };
    case 'complete':
      return {
        light: '#BCF738',
        dark: '#BCF738',
      };
    case 'error':
      return {
        light: '#F7D1B7',
        dark: '#F7D1B7',
      };
    case 'done':
      return {
        light: '#BCF738',
        dark: '#BCF738',
      };
    default:
      return {
        light: '#BDC0C4',
        dark: '#BDC0C4',
      };
  }
};

export const getIcon = str => {
  switch (str) {
    case 'active':
      return {
        icon: IconWaiting,
      };
    case 'complete':
      return {
        icon: IconWaiting,
      };
    case 'error':
      return {
        icon: IconError,
      };
    case 'done':
      return {
        icon: IconCheck,
      };
    default:
      return {
        icon: IconWaiting,
      };
  }
};

export const strIncludes = (value, reference = '') => {
  if (!value || value === '') {
    return true;
  } else {
    const newValue = value.replace(/\s/g, '');
    const newReference = reference.replace(/\s/g, '');
    return newReference.toLowerCase().includes(newValue.toLowerCase());
  }
};

export const FormatDate = ({
  date = null,
  short = false,
  options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
}) => {
  if (short) options.month = 'numeric';
  return new Date(date).toLocaleDateString('pt-br', options);
};
